import { Injectable } from '@angular/core';
import { ProductAppTags } from '../product-info/product-info.service';
import { ClassesService } from '../classes/classes.service';

@Injectable({
  providedIn: 'root'
})
export class LicenseInfoService {
  constructor(private classesService: ClassesService) { }

  public async currentClassesHaveLalilo() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.Lalilo));
  }

  public async currentClassesHaveFreckle() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.FreckleMath) || c.classAppTags.includes(ProductAppTags.FreckleReading));
  }

  public async currentClassesHaveMyOn() {
    let classes = await this.classesService.getClasses();
    return classes!.some(c => c.classAppTags.includes(ProductAppTags.Myon));
  }

  public async selectedClassHasMyon() {
    let selectedClass = await this.classesService.getSelectedClass();
    if (!selectedClass) {
      return false;
    }
    
    return  selectedClass.classAppTags.includes(ProductAppTags.Myon);
  }
}
