<button
  class="tab-button"
  [ngClass]="{'tab-button--active': isActiveTab(), 'tab-button--disabled': isTabDisabled()}"
  [attr.aria-label]="getAriaLabel()"
  [attr.aria-selected]="isActiveTab()"
  [attr.aria-disabled]="isTabDisabled()"
  (click)="onTabSelect()"
  role="tab"
  >
  <div class="label-container">
    <div class="header">
      <div class="header-title" [attr.header-title]="headerTitle">{{ headerTitle }}</div>
    </div>
    <div *ngIf="showNumStudents" [ngStyle]="{'border-color': color}" class="number-circle">{{ numStudents }}</div>
  </div>
</button>

