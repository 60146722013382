import { Injectable } from '@angular/core';
import { Category } from "src/app/shared/models/category.model";
import { ReadingPractice } from 'src/app/shared/models/reading-practice.model';
import { Recommendation, RecommendationType } from "src/app/shared/models/recommendation.model";
import { StudentContext } from 'src/app/shared/models/student.model';
import { DateHelper } from 'src/app/util/date-helper/date-helper';
import { SkillTests } from 'src/app/util/skill-tests/skill-tests';

@Injectable({
    providedIn: 'root'
})
export class ArRecommendationService {
  constructor(
  ) { }

  public async getArLexileRecommendation(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<Recommendation | null> {
    if (!selectedStudent.readingPractices || selectedStudent.readingPractices.length === 0) {
      return null;
    }

    const recentPassedReadingPractices = selectedStudent.readingPractices.filter(practice => 
      practice.product.toLowerCase() === 'ar' &&
      DateHelper.isWithinTwoWeeksOfToday(practice.mostRecentPracticedDate) &&
      !SkillTests.isStuckReadingPracticeActivity(practice)    
    );
    
    if (!recentPassedReadingPractices  || recentPassedReadingPractices.length === 0) {
      return null;
    }

    const averageLexileLevel = this.getAverageLexileLevel(recentPassedReadingPractices);
    const lowerLexileValue = averageLexileLevel - 150;
    const upperLexileValue = averageLexileLevel + 150;

    const arLexileRecommendation: Recommendation = {
      rationale: 'rationale',
      nudgeText: { skillsPractice: 'arSkillsPractice', readingPractice: 'arReadingPractice' },
      skillMetadata: null,
      recommendationType: RecommendationType.ArLexile,
      lowerLexile: lowerLexileValue,
      upperLexile: upperLexileValue,
      productActionLinks: {},
      productId: 'AR'
    } as Recommendation;
    
    return arLexileRecommendation;
  }

  private getAverageLexileLevel(readingPractices: ReadingPractice[]) { 
    return readingPractices.reduce((total, practice) => total + practice.lexileLevel, 0) / readingPractices.length
  }
}