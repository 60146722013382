import { Injectable } from '@angular/core';


@Injectable()
export class WindowFacadeService {

  public refresh(): void {
    if (window) {
      window.location.href = window.location.href;
    }
  }

  public navigate(url: string) {
    if (window) {
      window.location.href = url;
    }
  }

  public getHostName(): string {
      return window?.location?.hostname;
  }
}