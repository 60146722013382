import { Component, Input, OnDestroy, OnInit } from '@angular/core';


@Component({
  selector: 'set-up-savvas',
  templateUrl: './set-up-savvas.component.html',
  styleUrls: ['./set-up-savvas.component.scss']
})
export class SetUpSavvasComponent implements OnInit {
  public async ngOnInit(): Promise<void> {
  }
}
