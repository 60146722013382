import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivitySummaryTabOptions } from '../activity-summary-tab-options';

@Component({
  selector: 'app-activity-summary-tab',
  templateUrl: './activity-summary-tab.component.html',
  styleUrls: ['./activity-summary-tab.component.scss']
})
export class ActivitySummaryTabComponent {

  @Input() id: string = '';
  @Input() headerTitle: string = '';
  @Input() numStudents: Number = 0;
  @Input() showNumStudents: boolean = false;
  @Input() color: string = '';
  @Input() selectedTab: string = '';

  @Output() tabClicked = new EventEmitter<string>();

  isActiveTab(): boolean {
    let isActiveTab = this.selectedTab === this.id;
    return isActiveTab;
  }

  isTabDisabled(): boolean {
    return this.numStudents == 0;
  }

  onTabSelect() {
    if (!this.isTabDisabled()) {
      this.tabClicked.emit(this.id);
    }
  }

  getAriaLabel(): string {
    if (this.id == ActivitySummaryTabOptions.CategoryWholeClass) {
      return `Category ${this.headerTitle}`;
    }
    if (this.numStudents == 1) {
      return `Category ${this.headerTitle} contains ${this.numStudents} student`;
    }
    return `Category ${this.headerTitle} contains ${this.numStudents} students`;
  }
}

