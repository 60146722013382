import { Injectable } from '@angular/core';
import { Future } from '../../util/future';
import { RenaissanceJWT } from '../jwt-parser/jwt-parser.service';
import { User, UserResponse } from '../graphql/user-response';
import { GraphqlService } from '../graphql/graphql.service';
import { environment } from 'src/environments/environment';
import { StandardSet } from '../graphql/standard-set-response';
import { RgpStandardSet } from 'src/app/shared/models/rgp-standard-set.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _isLoggedIn = new Future<boolean>()
  private _jwt: RenaissanceJWT | null = null;
  private _clientId: string | null = null;
  private _userId: string | null = null;
  private _expiry: Date | null = null;
  private _rawJwt: string = '';
  private _homeUri: string = '';
  private _logoutUri: string = '';
  private _keepAliveUri: string = '';
  private _timeoutMinutes: number = 30; // Default timeout minutes
  private _keepAliveIntervalMinutes: number = 15; // Default keep alive interval minutes
  private _loginUrl: string = '';
  private _starSkillsUrl: string | null = null;
  private _appNavUrl: string | null = null;
  private _sessionKeepAliveUrl: string | null = null;
  private _userData: UserResponse | null = null;
  private _culture: string = '';
  private _featureSet: string = '';
  private _skipNavAccessCheck: boolean = false;
  private _hideOptOut: boolean = false;
  private _betaIsOver: boolean = false;
  private _teacherStandardSets: StandardSet[] = [];
  private _roleId: string = '';
  private _nextrole: string = '';
  private _rgpStandardSets: RgpStandardSet[] = [];
  private _appNavV2Url: string | null = null;
  private _rgpTeacherPreferencesUrl: string | null = null;

  constructor(
    private graphqlService: GraphqlService) { }

  isLoggedIn(): Promise<boolean> {
    return this._isLoggedIn.promise;
  }

  async getUserDetail(): Promise<User> {
    await this.ensureUserDataLoaded();
    return this._userData!.userDetail[0];
  }

  getClientId() {
    return this._clientId;
  }

  getUserId() {
    return this._userId;
  }

  getExpiry() {
    return this._expiry;
  }

  getRawJwt(): string | null {
    return this._rawJwt;
  }

  getJwt(): RenaissanceJWT | null {
    return this._jwt;
  }

  getHomeUri(): string {
    return this._homeUri;
  }

  getRgpBaseUrl(): string {
    let homeUri = this._jwt!.homeuri;
    let idx = homeUri!.indexOf('educatorportal');
    let baseUrl = homeUri.substring(0, idx);
    return baseUrl;
  }

  getLogoutUri(): string {
    return this._logoutUri;
  }

  getKeepAliveUri(): string {
    return this._keepAliveUri;
  }

  getZoneUri(): string {
    return new URL(this._homeUri).origin;
  }

  getTimeoutMinutes(): number {
    return this._timeoutMinutes;
  }

  getKeepAliveIntervalMinutes(): number {
    return this._keepAliveIntervalMinutes;
  }

  getLoginUrl(): string {
    return this._loginUrl;
  }

  getSessionKeepAliveUrl(): string {
    return this._sessionKeepAliveUrl ?? environment.sessionKeepAliveUrl;
  }

  getAppNavUrl(): string {
    return this._appNavUrl ?? environment.appNavUrl;
  }

  getAppNavV2Url(): string {
    return this._appNavV2Url ?? environment.appNavV2Url;
  }

  getRgpTeacherPreferencesUrl(): string {
    return this._rgpTeacherPreferencesUrl ?? environment.rgpTeacherPreferencesUrl;
  }

  getStarSkillsUrl(): string {
    return this._starSkillsUrl ?? environment.starSkillsUrl;
  }

  getCulture(): string {
    return this._culture;
  }

  getFeatureSet(): string {
    return this._featureSet;
  }

  getSkipNavAccessCheck(): boolean {
    return this._skipNavAccessCheck;
  }

  getHideOptOut(): boolean {
    return this._hideOptOut;
  }

  getBetaIsOver(): boolean {
    return this._betaIsOver;
  }

  getRoleId(): string {
    return this._roleId;
  }

  getNextRole(): string {
    return this._nextrole;
  }

  getStandardSets(): RgpStandardSet[] {
    return this._rgpStandardSets;
  }

  setUserSession(rawJwt: string, jwt: RenaissanceJWT) {
    if (jwt) {
      this._rawJwt = rawJwt
      this._jwt = jwt;
      this._clientId = jwt.clientid;
      this._userId = jwt.rid;
      this._expiry = new Date(1000 * jwt.exp);
      this._homeUri = jwt.homeuri;
      this._logoutUri = jwt.logouturi;
      this._keepAliveUri = jwt.keepaliveuri;
      this._timeoutMinutes = jwt.timeoutminutes;
      this._keepAliveIntervalMinutes = jwt.keepaliveintervalminutes;
      this._loginUrl = jwt.loginurl;
      this._roleId = jwt.roleid;
      this._nextrole = jwt.nextrole;
      if (jwt.globalbase) {
        this._sessionKeepAliveUrl = `${jwt.globalbase}teacherjourneyadapter/Session/KeepAlive`
        this._appNavUrl = `${jwt.globalbase}teacherjourneyadapter/AppNav`
        this._starSkillsUrl = `${jwt.globalbase}teacherjourneyadapter/Skills/`
        this._appNavV2Url = `${jwt.globalbase}teacherjourneyadapter/AppNavV2`
      }
      this._culture = jwt.culture;
      this._featureSet = jwt.featureset;
      this._hideOptOut = jwt.hideoptout;
      this._betaIsOver = jwt.betaisover;
      this._isLoggedIn.resolve(true);
      this._rgpStandardSets = jwt.standardsets;

      // skipnavaccesscheck claim is only used to support TJ ephemeral environments - we should never see it in other environments
      this._skipNavAccessCheck = jwt.skipnavaccesscheck ?? false;
    }
  }

  private async ensureUserDataLoaded() {
    if (!this._userData) {
      this._userData = await this.graphqlService.getUserData();
    }
  }
}
