import { Inject, Injectable } from '@angular/core';
import { HeaderElementConfig } from 'src/app/shared/models/header-element-config';
import { environment } from 'src/environments/environment';
import { UserService } from '../user/user.service';
import { ChatConfig } from 'src/app/shared/models/chat-config.model';
import { DOCUMENT } from '@angular/common';
import { AppNavConfig } from 'src/app/shared/models/app-nav-config.model';
import { AppNavItemService } from '../app-nav/app-nav-item.service';
import { AppNavItem } from 'src/app/shared/models/app-nav-item.model';
import { NextLeaderLinkGeneratorService } from '../link-generators/nextleader-link-generator.service';
import { AdminHandlerService } from '../admin-handler/admin-handler.service';
import { AppNavItemConfigurationService } from '../app-nav/app-nav-item-configuration.service';
import { FeatureToggleService } from '../feature-toggle/feature-toggle.service';
import { AppNavV2Service } from '../app-nav-v2/app-nav-v2.service';
import { ProductAppCodes } from '../product-info/product-info.service';

@Injectable({
  providedIn: 'root'
})
export class HeaderConfigService {
  private availableData: AppNavItem[] = [];
  private availableApps: AppNavItem[] = [];
  private otherAvailableApps: AppNavItem[] = [];
  private searchReports: AppNavItem[] = []
  private isDegradedNav: boolean = false;
  private useLaunchControl: boolean = false;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private userService: UserService,
    private appNavItemService: AppNavItemService,
    private nextLeaderLinkGeneratorService: NextLeaderLinkGeneratorService,
    private adminHandlerService: AdminHandlerService,
    private appNavItemConfigurationService: AppNavItemConfigurationService,
    public featureToggleService: FeatureToggleService,
    private appNavV2Service: AppNavV2Service,
  ) { }

  public async getConfig(): Promise<HeaderElementConfig> {
    let userDetail = (await this.userService.getUserDetail());
    let rgpBaseUrl = this.userService.getRgpBaseUrl();
    let homeUri = this.userService.getHomeUri();

    let chatConfig: ChatConfig = {
      customChatJSFile: environment.chatConfigScriptUrl,
      customerId: this.userService.getClientId()!,
      userToken: '',
      host: this.document.host
    }

    let nextLeaderNavLink = this.nextLeaderLinkGeneratorService.getNextLeaderPageLink();

    if (await this.featureToggleService.isTrueAsync("use-new-launch")) {
      this.useLaunchControl = true;
      await this.appNavV2Service.initializeAppNavV2();
      this.isDegradedNav = this.appNavV2Service.getIsDegraded();
      if (this.isDegradedNav == false) {
        this.availableApps = await this.appNavV2Service.getAvailableV2Apps();
        this.availableData = this.appNavV2Service.getAvailableV2MyData();
        this.otherAvailableApps = await this.appNavV2Service.getOtherAvailableApps();
        this.availableData = await this.appNavV2Service.resolveLinks(this.availableData);

        this.searchReports = await this.appNavV2Service.getAvailableV2SearchReports();
        this.searchReports = await this.appNavV2Service.resolveLinks(this.searchReports);
      }
      else {
        this.availableApps = this.appNavV2Service.getAppsForDegradedMode();
      }

      if (this.adminHandlerService.isAdmin()) {
        this.availableApps = this.availableApps.filter(x => x.appCode != ProductAppCodes.RenAnalytics
          && x.appCode != ProductAppCodes.SchoolZillaStarter
          && x.appCode != ProductAppCodes.Schoolzilla);
      }
    }
    else {
      this.useLaunchControl = false;
      await this.appNavItemService.initialize();
      this.availableData = this.appNavItemService.getAvailableMyData();
      this.availableApps = this.appNavItemService.getAvailableApps();
      this.otherAvailableApps = await this.appNavV2Service.getOtherAvailableApps();
      this.isDegradedNav = this.appNavItemService.getIsDegraded();

      this.availableApps = await this.appNavItemService.resolveLinks(this.availableApps);
      this.availableData = await this.appNavItemService.resolveLinks(this.availableData);

      if (this.adminHandlerService.isAdmin()) {
        this.availableApps = this.availableApps.filter(x => x.applicationId != this.appNavItemConfigurationService.AppId.renAnalytics
          && x.applicationId != this.appNavItemConfigurationService.AppId.schoolZillaStarter);
      }

      // Add search reports to mobile header waffle menu
      this.searchReports = await this.appNavItemService.getAvailableSearchReports();
      this.searchReports = await this.appNavItemService.resolveLinks(this.searchReports);
    }

    let headerAppNavConfig: AppNavConfig = {
      availableApps: this.availableApps,
      availableData: this.availableData,
      otherAvailableApps: this.otherAvailableApps,
      searchReports: this.searchReports,
      launchBaseUri: homeUri,
      isDegraded: this.isDegradedNav,
      useLaunchControl: this.useLaunchControl,
      nextAppNavConfig: this.adminHandlerService.isAdmin() ? { nextLeaderNavLink: nextLeaderNavLink, canSwitchToLeader: true } : undefined
    };

    // required config
    let userName = !userDetail ? '' : userDetail.firstName + ' ' + userDetail.lastName;

    let headerElementConfig: HeaderElementConfig = {
      logoutUrl: 'logout',
      userName: userName,
      baseUrl: environment.headerConfig.url,
      version: environment.headerConfig.version,
      hideUserNameDropdown: false,
      culture: this.userService.getCulture(),
      resourceSiteUrl: environment.rgpResourceSiteUrl,
      launchControlBaseUrl: environment.launchControlServiceUrl,
      accessToken: '',
    };

    headerElementConfig.supportLargerScreenWidth = true;

    // user menu
    headerElementConfig.settingsUrl = `${rgpBaseUrl}userprofileportal/`;
    headerElementConfig.manageAppsUrl = `${rgpBaseUrl}sismanagement/`;
    headerElementConfig.lockedStudentsUrl = `${rgpBaseUrl}sismanagement/users/unlock-my-students/`;
    headerElementConfig.preferenceUrl = `${rgpBaseUrl}preferenceportal/`;

    // nav menu
    headerElementConfig.appNavConfig = headerAppNavConfig;

    // help menu
    headerElementConfig.chatConfig = chatConfig;

    return headerElementConfig;
  }

  public getElementUrl(): string {
    let headerElementUrl = `${environment.headerConfig.url}/main.js`;
    return headerElementUrl;
  }
}
