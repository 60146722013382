import { Injectable } from "@angular/core";
import { AppNavItem, AppNavItemIds } from "src/app/shared/models/app-nav-item.model";
import { ProductAppCodes, ProductAppDisplayNames, ProductAppImagePrefix } from "../product-info/product-info.service";

@Injectable({
  providedIn: 'root'
})

export class AppNavV2ItemConfigurationService {

  public getStarPhonicsReports() {
    let item: AppNavItem = {
      displayName: 'Star Phonics Reports',
      imagePrefix: ProductAppImagePrefix.Star,
      appCode: ProductAppCodes.StarPhonics
    };
    return item;
  }

  public getFreckleReports() {
    let item: AppNavItem = {
      displayName: 'Freckle Reports',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle
    };
    return item;
  }

  public getMyONReports() {
    let item: AppNavItem = {
      displayName: 'myON Reports',
      imagePrefix: ProductAppImagePrefix.Myon,
      appCode: ProductAppCodes.Myon,
      deepLink: 'MYON_REPORTS'
    };
    return item;
  }

  public getLaliloReports() {
    let item: AppNavItem = {
      displayName: 'Lalilo Reports',
      imagePrefix: ProductAppImagePrefix.Lalilo,
      appCode: ProductAppCodes.Lalilo,
      url: 'class-snapshot'
    };
    return item;
  }

  //freckle reports are here because they have dynamic urls that need info from teacher journey to build
  //so it didn't make sense to put them in educator portal
  public getFreckleSearchReports() {
    let items: AppNavItem[] = [];
    items.push({
      displayName: 'Math Levels',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'math-diagnostic-report',
      subHeader: 'View and edit starting pretest levels, current levels, and growth data across domains.'
    });

    items.push({
      displayName: 'Report Cards',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'report-card-summaries',
      subHeader: 'See each student\'s math performance by domain.'
    });

    items.push({
      displayName: 'Performance by Standard',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'math-standards-report',
      subHeader: 'View class performance on specific standards.'
    });

    items.push({
      displayName: 'Student Goal Setting',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'student-goals',
      subHeader: 'View status and progress of your students\' goals.',
    });

    items.push({
      displayName: 'Class Grouping',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'class-grouping-report',
      subHeader: 'Group students by their level (same or mixed).'
    });

    items.push({
      displayName: 'Skills Progress',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'math-skills-progress-report',
      subHeader: 'See which skills students have mastered in Focus Skills and High School Courses.'
    });

    items.push({
      displayName: 'Assessments',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'math-assessment-growth',
      subHeader: 'Track student growth across assessments.'
    });

    items.push({
      displayName: 'Fact Practice',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'fact-practice-reports',
      subHeader: 'Monitor student fact mastery.'
    });

    items.push({
      displayName: 'ELA Levels',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'ela/reports/levels',
      subHeader: 'View student levels in Reading and Word Study to track growth.'
    });

    items.push({
      displayName: 'ELA Report Cards',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'ela/report-cards',
      subHeader: 'A summary of each student\'s reading and word study performance.'
    });

    items.push({
      displayName: 'Student Goal Setting',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'ela/student-goals',
      subHeader: 'View status and progress of your students\' goals.'
    });

    items.push({
      displayName: 'Performance by Standard',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'ela/reports/performance-by-standard',
      subHeader: 'Use this to see how your class is performing on grade level standards.'
    });

    items.push({
      displayName: 'Class Grouping',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      subHeader: 'Your students grouped by reading level or skill.'
    });

    items.push({
      displayName: 'Word Study Matrix',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      subHeader: 'A matrix of each student\'s word study level.'
    });

    items.push({
      displayName: 'Assessments Report',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'ela/reports/assessments',
      subHeader: 'Assessment performance for your classes and students.'
    });

    items.push({
      displayName: 'Article & Story Activity',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'session-history/ela-article',
      subHeader: 'See which ELA articles your students have read, at what level, and how well they\'re comprehending them.'
    });

    items.push({
      displayName: 'Social Studies Student Activity',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'session-history/social-studies',
      subHeader: 'See student performance on Social Studies articles'
    });

    items.push({
      displayName: 'Science Student Activity',
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle,
      url: 'session-history/science',
      subHeader: 'See student performance on Science articles'
    });

    return items;
  }

  //lalilo reports are here because they have dynamic urls that need info from teacher journey to build
  //so it didn't make sense to put them in educator portal
  public getLaliloSearchReports() {
    let items: AppNavItem[] = [];
    items.push({
      displayName: 'Lesson Progress',
      imagePrefix: ProductAppImagePrefix.Lalilo,
      appCode: ProductAppCodes.Lalilo,
      url: 'lesson-progress',
      subHeader: "Shows extensive data about students' proficiency on all Lalilo's Scope & Sequence"
    });

    items.push({
      displayName: 'Placement Test Result',
      imagePrefix: ProductAppImagePrefix.Lalilo,
      appCode: ProductAppCodes.Lalilo,
      url: 'students-progress',
      subHeader: "Shows where students are placed in Lalilo's progression after taking a Star Asssessment or Lalilo's placement test"
    });

    items.push({
      displayName: 'Assignment Report',
      imagePrefix: ProductAppImagePrefix.Lalilo,
      appCode: ProductAppCodes.Lalilo,
      url: 'assignment-report',
      subHeader: "Shows completion and proficiency data on assignments created by the teacher"
    });

    return items;
  }

  public getAppsForDegradedMode() {
    let MYAPPS_NAVS_AR: AppNavItem = {
      id: AppNavItemIds.AR,
      displayName: ProductAppDisplayNames.AR,
      imagePrefix: ProductAppImagePrefix.AR,
      children: this.getArItems()
    }

    let MYAPPS_NAVS_FRECKLE: AppNavItem = {
      displayName: ProductAppDisplayNames.Freckle,
      imagePrefix: ProductAppImagePrefix.Freckle,
      appCode: ProductAppCodes.Freckle

    };

    let MYAPPS_NAVS_LALILO: AppNavItem = {
      displayName: ProductAppDisplayNames.Lalilo,
      imagePrefix: ProductAppImagePrefix.Lalilo,
      appCode: ProductAppCodes.Lalilo
    };

    let MYAPPS_NAVS_MYON: AppNavItem = {
      displayName: ProductAppDisplayNames.Myon,
      imagePrefix: ProductAppImagePrefix.Myon,
      appCode: ProductAppCodes.Myon
    };

    let MYAPPS_NAVS_NEARPOD: AppNavItem = {
      displayName: 'Nearpod',
      imagePrefix: 'nearpod_logo',
      url: 'https://nearpod.com/',
    };

    let MYAPPS_NAVS_SCHOOLZILLA: AppNavItem = {
      displayName: ProductAppDisplayNames.Schoolzilla,
      imagePrefix: ProductAppImagePrefix.Schoolzilla,
      appCode: ProductAppCodes.Schoolzilla
    };

    let MYAPPS_NAVS_STAR: AppNavItem = {
      id: AppNavItemIds.STAR,
      displayName: ProductAppDisplayNames.Star,
      imagePrefix: ProductAppImagePrefix.Star,
      longDisplayName: 'Star Assessments',
      children: this.getStarItems()
    };

    let MYAPPS_NAVS_RENU: AppNavItem = {
      id: AppNavItemIds.RENU,
      displayName: ProductAppDisplayNames.RENU,
      imagePrefix: ProductAppImagePrefix.RENU,
      appCode: ProductAppCodes.RENU
    }

    let MYAPPS_NAVS: AppNavItem[] =
      [
        // most are alphabetical:
        MYAPPS_NAVS_AR,
        MYAPPS_NAVS_FRECKLE,
        MYAPPS_NAVS_LALILO,
        MYAPPS_NAVS_MYON,
        MYAPPS_NAVS_NEARPOD,
        MYAPPS_NAVS_SCHOOLZILLA,
        MYAPPS_NAVS_STAR,
        // REN U is last
        MYAPPS_NAVS_RENU,
      ];

    return MYAPPS_NAVS;
  }

  private getArItems() {
    let items: AppNavItem[] = []
    items.push({
      displayName: 'Independent Reading',
      subHeader: 'Set goals for books and articles',
      applicationId: '1',
      children: [
        {
          displayName: 'Record Book & Goals',
          appCode: ProductAppCodes.AR
        },
        {
          displayName: 'Reports',
          appCode: ProductAppCodes.AR,
          deepLinkId: "reports"
        },
        {
          displayName: 'Manage Books & Quizzes',
          appCode: ProductAppCodes.AR,
          deepLinkId: "manage-books-quizzes"
        },
        {
          displayName: 'Share Reading Snapshots',
          appCode: ProductAppCodes.AR,
          deepLinkId: "share-reading-snapshots"
        },
        {
          displayName: 'Preferences',
          appCode: ProductAppCodes.AR,
          deepLinkId: "preferences"
        },
        {
          displayName: 'Resources',
          appCode: ProductAppCodes.AR,
          deepLinkId: "resources"
        },
      ]
    });

    items.push({
      displayName: 'Manage Content',
      subHeader: 'Books, quizzes, articles or assessments',
      appCode: ProductAppCodes.AR,
      deepLinkId: "manage-content"
    });
    items.push({
      displayName: 'Reading Snapshots',
      subHeader: 'Create and manage shareable snapshots',
      appCode: ProductAppCodes.AR,
      deepLinkId: "reading-snapshots"
    });

    return items;
  }

  private getStarItems() {
    let items: AppNavItem[] = [];
    items.push(
      {
        displayName: 'Star Reading',
        subHeader: 'For Grades K-12',
        appCode: ProductAppCodes.StarReading,
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            appCode: ProductAppCodes.StarReading,
          },
          {
            displayName: 'Record Book',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "record-book"
          },
          {
            displayName: 'Data Insights',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Reports',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "reports"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "resources"
          },
          {
            displayName: 'Third Grade Reading Proficiency Assessment',
            appCode: ProductAppCodes.StarReading,
            deepLinkId: "third-grade-reading-proficiency-assessment"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Early Literacy',
        subHeader: 'For Grades pre-K-3',
        appCode: ProductAppCodes.StarEarlyLiteracy,
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            appCode: ProductAppCodes.StarEarlyLiteracy,
          },
          {
            displayName: 'Record Book',
            appCode: ProductAppCodes.StarEarlyLiteracy,
            deepLinkId: "record-book"
          },
          {
            displayName: 'Data Insights',
            appCode: ProductAppCodes.StarEarlyLiteracy,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Reports',
            appCode: ProductAppCodes.StarEarlyLiteracy,
            deepLinkId: "reports"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarEarlyLiteracy,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
            appCode: ProductAppCodes.StarEarlyLiteracy,
            deepLinkId: "resources"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Math',
        subHeader: 'For Grades K-12',
        appCode: ProductAppCodes.StarMath,
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            appCode: ProductAppCodes.StarMath
          },
          {
            displayName: 'Record Book',
            appCode: ProductAppCodes.StarMath,
            deepLinkId: "record-book"
          },
          {
            displayName: 'Data Insights',
           appCode: ProductAppCodes.StarMath,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Reports',
           appCode: ProductAppCodes.StarMath,
            deepLinkId: "reports"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarMath,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
            appCode: ProductAppCodes.StarMath,
            deepLinkId: "resources"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Reading',
        subHeader: 'For Grades K-6',
        appCode: ProductAppCodes.StarCbmReading,
        children: [
          {
            displayName: 'Record Book & Assessments',
            appCode: ProductAppCodes.StarCbmReading
          },
          {
            displayName: 'Data Insights',
            appCode: ProductAppCodes.StarCbmReading,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Caregiver Report',
            appCode: ProductAppCodes.StarCbmReading,
            deepLinkId: "caregiver-report"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarCbmReading,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
           appCode: ProductAppCodes.StarCbmReading,
            deepLinkId: "resources"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Math',
        subHeader: 'For Grades K-3',
        appCode: ProductAppCodes.StarCbmMath,
        children: [
          {
            displayName: 'Record Book & Assessments',
            appCode: ProductAppCodes.StarCbmMath
          },
          {
            displayName: 'Data Insights',
            appCode: ProductAppCodes.StarCbmMath,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Caregiver Report',
             appCode: ProductAppCodes.StarCbmMath,
            deepLinkId: "caregiver-report"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarCbmMath,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
             appCode: ProductAppCodes.StarCbmMath,
            deepLinkId: "resources"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star CBM Lectura',
        subHeader: 'For Grades K-6',
        appCode: ProductAppCodes.StarCbmLectura,
        children: [
          {
            displayName: 'Record Book & Assessments',
            appCode: ProductAppCodes.StarCbmLectura
          },
          {
            displayName: 'Data Insights',
            appCode: ProductAppCodes.StarCbmLectura,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Caregiver Report',
           appCode: ProductAppCodes.StarCbmLectura,
            deepLinkId: "caregiver-report"
          },
          {
            displayName: 'Preferences',
             appCode: ProductAppCodes.StarCbmLectura,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Resources',
            appCode: ProductAppCodes.StarCbmLectura,
            deepLinkId: "resources"
          }
        ]
      }
    );
    items.push(
      {
        displayName: 'Star Spanish',
        subHeader: 'For Grades 1-12',
        appCode: ProductAppCodes.StarReadingSpanish,
        children: [
          {
            displayName: 'Manage Goals & Benchmarks',
            appCode: ProductAppCodes.StarReadingSpanish
          },
          {
            displayName: 'Record Book',
            appCode: ProductAppCodes.StarReadingSpanish,
            deepLinkId: "record-book"
          },
          {
            displayName: 'Data Insights',
           appCode: ProductAppCodes.StarReadingSpanish,
            deepLinkId: "data-insights"
          },
          {
            displayName: 'Reports',
            appCode: ProductAppCodes.StarReadingSpanish,
            deepLinkId: "reports"
          },
          {
            displayName: 'Preferences',
            appCode: ProductAppCodes.StarReadingSpanish,
            deepLinkId: "preferences"
          },
          {
            displayName: 'Reading Resources',
          appCode: ProductAppCodes.StarReadingSpanish,
            deepLinkId: "reading-resources"
          },
          {
            displayName: 'Early Literacy Resources',
            appCode: ProductAppCodes.StarEarlyLiteracySpanish,
          },
          {
            displayName: 'Math Resources',
            appCode: ProductAppCodes.StarMathSpanish,
          }
        ]
      }
    );
    items.push({
      displayName: 'Star Preschool',
      subHeader: 'Early Education and Pre-K',
      appCode: ProductAppCodes.StarPreschool,
      children: [
        {
          displayName: 'Assessment Dashboard & Assessments',
          appCode: ProductAppCodes.StarPreschool
        },
        {
          displayName: 'Resources',
          appCode: ProductAppCodes.StarPreschool,
          deepLinkId: "resources"
        }
      ]
    });
    items.push({
      displayName: 'Star Phonics',
      subHeader: 'For Grades K-6+',
      appCode: ProductAppCodes.StarPhonics,
      children: [
        {
          displayName: 'Educator Login',
          appCode: ProductAppCodes.StarPhonics,
        },
        {
          displayName: 'Student Test Code Page',
          appCode: ProductAppCodes.StarPhonics,
          deepLinkId: "test-code-page"
        }
      ]
    });
    items.push(
      {
        displayName: 'Star Custom',
        subHeader: 'Tests designed for specific skills',
        appCode: ProductAppCodes.StarCustom
      }
    );
    items.push({
      displayName: 'SAEBRS',
      subHeader: 'For Grades K-12',
      appCode: ProductAppCodes.Saebrs
    });
    items.push(
      {
        displayName: 'Planner',
        subHeader: 'Assign articles, quizzes, and assignments',
        appCode: ProductAppCodes.Planner
      }
    );

    return items;
  }

  public getOtherAppNavs() {
    let OTHERAPPS_NAVS_SAVVAS: AppNavItem = {
      displayName: 'Savvas',
      id: AppNavItemIds.SAVVAS,
      imagePrefix: 'savvas_logo',
      navigateFromCallingRepo: true,
      navigateMethodName: 'appLaunch',
      navigateServiceName: 'savvasLinkGeneratorService'
    }

    let OTHERAPPS_NAVS: AppNavItem[] =
      [
        OTHERAPPS_NAVS_SAVVAS,
      ];

    return OTHERAPPS_NAVS;
  }
}
