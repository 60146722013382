import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ParamMap, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { JwtParserService } from './services/jwt-parser/jwt-parser.service';
import { UserService } from './services/user/user.service';
import { AvailableAppNavService } from './services/app-nav/available-app-nav.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { FeatureToggleService } from './services/feature-toggle/feature-toggle.service';
import { InvalidTokenError } from 'jwt-decode';
import { HasuraCacheService } from './services/hasura-cache/hasura-cache.service';
import { AvailableAppNavV2Service } from './services/app-nav-v2/available-app-nav-v2.service';
import { KeepaliveService } from './idle/keepalive.service';
import { WindowFacadeService } from './services/window/window.service';

@Injectable({
  providedIn: 'root'
})

export class AppRoutingGuard {
  constructor(
    private router: Router,
    private jwtParser: JwtParserService,
    public userService: UserService,
    public appNavService: AvailableAppNavService,
    private storageService: LocalStorageService,
    private toggleService: FeatureToggleService,
    private cacheService: HasuraCacheService,
    private appNavServiceV2: AvailableAppNavV2Service,
    private keepAliveService: KeepaliveService,
    private windowFacadeService: WindowFacadeService
  ) {
  }

  /**
   * Checks if the JWT is valid and initializes the app if it is.
   * If the JWT came from the url, it will be removed from the url, stored in
   * localStorage, and the app will be reloaded.
   */
  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    return await this.parseJwtAndLogin(route.queryParamMap)
  }

  async parseJwtAndLogin(paramMap: ParamMap): Promise<boolean> {
    let jwt = paramMap.get('jwt');
    let validJwt = false

    if (jwt) {
      validJwt = await this.validateToken(jwt);
      if (!validJwt) return false

      // Store the jwt in localStorage
      this.storageService.setItem('token', jwt);

      // Force refresh the hasura queries cache when a jwt is in the URL (new session / initial login)
      this.cacheService.setForceRefresh();

      const queryParams = this.removeParamFromUrl(paramMap, ['jwt']);

      const decoded = this.jwtParser.decode(jwt);
      if (decoded.deeplink) {
        this.router.navigateByUrl(`/${decoded.deeplink}`, { replaceUrl: true });
      }
      else {
        // Remove the jwt from the url and reload the page
        this.router.navigate([], { queryParams, replaceUrl: true });
      }
    }
    else {
      // Get jwt from localStorage
      jwt = this.storageService.getItem('token') || 'invalid_jwt';

      validJwt = await this.validateToken(jwt);
    }

    // this will return unathorized if the user directly logged out of rpg
    // even if the jwt is still valid. Which will redirect the user to rgp to log in again.
    // due to CORS we can not use this in ephemeral environments
    if (await this.toggleService.isTrueAsync('ping-keepalive-on-app-load') && this.isNotEph()) {
      await this.keepAliveService.ping();
    }

    return validJwt;
  }
  isNotEph() {
    return this.windowFacadeService.getHostName() === 'teacher.dev.renaissance.com'
        || this.windowFacadeService.getHostName() === 'teacher.renaissance.com'
        || this.windowFacadeService.getHostName() === 'teacher.stage.renaissance.com'
        || this.windowFacadeService.getHostName() === 'localhost';
  }

  /**
   * Validates the JWT and initializes the app if the JWT is valid.
   * InvalidTokenError (missing or malformed jwt) = Redirect to expiredJwtRedirectUrl
   * Expired JWT = Redirect to homeuri
   */
  async validateToken(jwt: string): Promise<boolean> {
    try {
      const decoded = this.jwtParser.decode(jwt);

      if (!this.jwtParser.verify(jwt)) {
        this.windowFacadeService.navigate(decoded.homeuri);

        return false
      }

      this.userService.setUserSession(jwt, decoded);
      await this.toggleService.initialize(decoded.clientid);

      if (await this.toggleService.isTrueAsync("use-new-launch")) {
        await this.appNavServiceV2.loadAppNavV2();
      }
      this.appNavService.loadAppNav();

      return true
    } catch (error: any) {
      if (error instanceof InvalidTokenError) {
        console.error('Error validating JWT: ', error.message);
      }

      this.windowFacadeService.navigate(environment.expiredJwtRedirectUrl);

      return false
    }
  }

  /**
   * Removes the specified keys from the url and navigates to the new url.
   * Used to remove the params from the url after they have been processed.
   */
  private removeParamFromUrl(paramMap: ParamMap, keysToRemove: string[]): {} {
    const queryParams = {} as any;
    const keysToKeep = paramMap.keys.filter(k => !keysToRemove.includes(k));
    keysToKeep.forEach(k => (queryParams[k] = paramMap.get(k)));

    return queryParams
  }
}
