import { DOCUMENT } from "@angular/common";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable, catchError, throwError, tap } from "rxjs";
import { JwtParserService } from "src/app/services/jwt-parser/jwt-parser.service";
import { environment } from "src/environments/environment";
import { UserService } from "./user/user.service";


@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private jwtParser: JwtParserService,
    private userService: UserService
  ) {
    this.window = this.document.defaultView as Window;
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modifiedRequest;

    if (!request.url.endsWith('identityservice/sso/ping') && !request.url.endsWith('identityservice/sso/v2/ping')) {
      modifiedRequest = request.clone({
        headers: request.headers.set('rli-referer-app-name', 'teacher-journey-frontend')
      });
    }
    else {
      modifiedRequest = request;
    }

    const jwt = this.userService.getRawJwt();

    if (jwt) {
      let clonedRequest;
      if (!request.url.endsWith('identityservice/sso/ping') && !request.url.endsWith('identityservice/sso/v2/ping')) {
        clonedRequest = modifiedRequest.clone({
          headers: modifiedRequest.headers.set("Authorization", "Bearer " + jwt)
        });
      }
      else {
        clonedRequest = modifiedRequest;
      }

      return next.handle(clonedRequest).pipe(
        tap((response: any) => {
          // GraphQL will also return errors in a 200 response :(
          if (response?.body?.errors?.length > 0) {
            const jwtErrors = response.body.errors.filter((error: any) => {
              return error?.extensions?.code === 'invalid-jwt';
            })
            if (jwtErrors.length > 0) {
              // Redirect back to educator portal if any jwt errors in gql repsonse body
              let decoded = this.jwtParser.decode(jwt);
              this.window.location = decoded.homeuri;
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {

          // Redirect to educator portal if backend received an expired jwt
          if (error.status === 401) {
            let decoded = this.jwtParser.decode(jwt);
            this.window.location = decoded.homeuri;
          }
          // re-throw
          return throwError(() => error);
        })
      );
    }
    else {
      return next.handle(modifiedRequest).pipe(
        catchError((error: HttpErrorResponse) => {

          // redirect to school finder if no jwt in local storage
          if (error.status === 401) {
            this.window.location = environment.expiredJwtRedirectUrl;
          }
          // re-throw
          return throwError(() => error);
        })
      );
    }
  }
}
