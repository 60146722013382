import { Component, Inject } from '@angular/core';
import { FeatureToggleService } from '../services/feature-toggle/feature-toggle.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { KeepaliveService } from '../idle/keepalive.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent {
  public teacherJourneyText = "Renaissance Teacher Insights";
  private teacherJourneyTitleText = "Renaissance Teacher Insights";
  private teacherJourneySearchResultsText = "Search Results";
  private window: Window;

  constructor(
    private featureToggleService: FeatureToggleService,
    private router: Router,
    @Inject(DOCUMENT) private document: Document,
    private keepAliveService: KeepaliveService
  ) {
    featureToggleService.isTrueAsync('tj-sabotage-javascript-error').then(forceFail => {
      if (forceFail) {
        throw new Error('force fail from sabotage toggle');
      }
    });

    this.window = this.document.defaultView as Window

    //this will fire when the back button is pressed from another domain
    //we need to ping the keepalive service to determine if the user was logged out of rgp directly
    //if they were the keep alive service will return a 401 and we will redirect to the login page
    this.window.addEventListener("pageshow", async (event) => {
      if (await this.featureToggleService.isTrueAsync('ping-keepalive-on-app-load') && this.isNotEph()) {
        await this.keepAliveService.ping();
      }
    }, false);

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((route: any) => {
        if (route?.url) {
          let url = route.url;
          if (url.includes('search')) {
            this.teacherJourneyText = this.teacherJourneySearchResultsText;
          } else {
            this.teacherJourneyText = this.teacherJourneyTitleText;
          }
        }
      });
  }

  isNotEph() {
    return this.window.location.hostname === 'teacher.dev.renaissance.com'
      || this.window.location.hostname === 'teacher.renaissance.com'
      || this.window.location.hostname === 'teacher.stage.renaissance.com'
      || this.window.location.hostname === 'localhost';
  }
}
