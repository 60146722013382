import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { TJClass, ClassActivity } from "../../shared/models/class.model";
import { GraphqlService } from "../graphql/graphql.service";
import { StudentActivity } from "src/app/shared/models/student.model";
import { Enrollment } from "../graphql/student-activity-response";
import { Assessment, AssessmentDetail } from "src/app/shared/models/assessment.model";
import { Skill, SkillDetail } from "src/app/shared/models/skill.model";
import { ReadingPractice, ReadingPracticeDetail, ReadingPracticeSummary, ReadingPracticeSummaryDetail } from "src/app/shared/models/reading-practice.model";
import { DateHelper } from "src/app/util/date-helper/date-helper";
import { NewRelicInstrumentationService } from "../new-relic-instrumentation/new-relic-instrumentation.service";
import { StandardSet } from "../graphql/standard-set-response";
import { SchoolsService } from "../schools/schools.service";
import { SkillPracticeSummary } from "src/app/shared/models/skill-practice-summary.model";

export enum ReadingPracticeProducts {
  MYON = 'MYON',
  AR = 'AR'
};

@Injectable({
  providedIn: 'root'
})
export class ClassesService {
  private _selectedClass$ = new BehaviorSubject<ClassActivity | undefined>(undefined);
  public get selectedClass$(): BehaviorSubject<ClassActivity | undefined> { return this._selectedClass$ }
  private tjClasses: TJClass[] | undefined;
  private skillDetail: SkillDetail[] = [];
  private skillSummary: SkillPracticeSummary[] = [];
  private assessmentDetail: AssessmentDetail[] = [];
  private readingPracticeSummaryDetail: ReadingPracticeSummaryDetail[] = [];
  private readingPracticeDetail: ReadingPracticeDetail[] = [];
  private classStandardSets: StandardSet[] = [];

  public constructor(
    private graphqlService: GraphqlService,
    private newRelicInstrumantation: NewRelicInstrumentationService,
    private schoolsService: SchoolsService) { }

  public async getClasses(schoolRenaissanceId: string = ''): Promise<TJClass[]> {
    if (!this.tjClasses) {
      if (!schoolRenaissanceId) {
        if (!this.schoolsService.selectedSchool$.value) {
          let schools = await this.schoolsService.getSchools();
          schoolRenaissanceId = schools[0].schoolRenaissanceId;
        }

        else {
          schoolRenaissanceId = this.schoolsService.selectedSchool$.value.schoolRenaissanceId;
        }
      }
      return await this.refreshClasses(schoolRenaissanceId);
    }
    return this.tjClasses?.slice() ?? [];
  }

  public async getSelectedClass(): Promise<TJClass | null> {
    if (!this.selectedClass$.value) {
      return null;
    }
    
    return this.tjClasses?.find(c => c.classRenaissanceId === this.selectedClass$.value?.renaissanceClassId) ?? null; 
  }

  public async refreshClasses(schoolRenaissanceId: string): Promise<TJClass[]> {
    this.tjClasses = (await this.graphqlService.getClasses(schoolRenaissanceId)).slice();
    this.tjClasses.sort(this.sortClasses);
    return this.tjClasses;
  }

  public async updateClassActivity(className: string, classRenaissanceId: string, classAppTags: string, teacherRenaissanceId: string): Promise<ClassActivity> {
    const response = await this.graphqlService.getStudentActivity(classRenaissanceId, teacherRenaissanceId);

    this.newRelicInstrumantation.recordStudentActivityLoadInfo(response);

    const studentEnrollments = response.studentEnrollments;
    const skillDetail = response.studentSkillDetail;
    const skillPracticeSummary = response.studentSkillPracticeSummary;
    const assessmentDetail = response.studentAssessmentDetail;
    const readingPracticeSummaryDetail = response.studentReadingPracticeSummary;
    const readingPracticeDetail = response.studentReadingPracticeDetail;

    // Put enrollment details in their own properties
    this.skillDetail = skillDetail;
    this.skillSummary = skillPracticeSummary;
    this.assessmentDetail = assessmentDetail;
    this.readingPracticeSummaryDetail = readingPracticeSummaryDetail;
    this.readingPracticeDetail = readingPracticeDetail;

    // Group the enrollment data based on classes
    let selectedClassActivity = this.getClassActivityFromResponse(className, classRenaissanceId, classAppTags, studentEnrollments);
    this.selectedClass$.next(selectedClassActivity);

    return selectedClassActivity;
  }


  private getClassActivityFromResponse(className: string, classRenaissanceId: string, classAppTags: string, enrollments: Enrollment[]): ClassActivity {
    let appTags = classAppTags.split(",") ?? [];
    let classStudents: StudentActivity[] = [];
    let teacherRenaissanceId = enrollments[0]?.teacherRenaissanceId;

    enrollments.forEach(studentEnrollment => {
      let newStudent = this.getStudentActivityFromEnrollment(studentEnrollment);

      classStudents.push(newStudent);
    });

    let newClass: ClassActivity = {
      title: className,
      renaissanceClassId: classRenaissanceId,
      appTags: appTags,
      students: classStudents,
      teacherRenaissanceId
    }

    return newClass;
  }

  private sortClasses(aClass: TJClass, bClass: TJClass) {
    // sort classes alphabetically using class title, then class Id
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
    return aClass.className.localeCompare(bClass.className) || aClass.classRenaissanceId.localeCompare(bClass.classRenaissanceId);
  }

  private getStudentActivityFromEnrollment(student: Enrollment) {
    let skills = this.getStudentSkillsFromSkillDetail(student.studentRenaissanceId);
    let skillSummary = this.getStudentSkillSummary(student.studentRenaissanceId);
    let assessments = this.getStudentAssessmentsFromAssessmentDetail(student.studentRenaissanceId);
    let readingPracticeSummary = this.getStudentReadingPracticeSummaryFromReadingPracticeSummaryDetail(student.studentRenaissanceId);
    let readingPractices = this.getStudentReadingPracticesFromReadingPracticeDetail(student.studentRenaissanceId);

    let newStudent: StudentActivity = {
      firstName: student.givenName,
      lastName: student.familyName,
      grade: student.grade,
      renaissanceId: student.studentRenaissanceId,
      schoolRenaissanceId: student.schoolRenaissanceId,
      assessments: [...assessments],
      skills: [...skills],
      skillPracticeSummary: [...skillSummary],
      readingPractices: [...readingPractices],
      readingPracticeSummary: readingPracticeSummary,
      studentIds: { ...student.studentIds },
      studentClassIds: { ...student.studentClassIds },
    };

    return newStudent;
  }

  private getStudentSkillSummary(studentRenaissanceId: string): SkillPracticeSummary[] {
    let studentSkillSummary = this.skillSummary.filter(skillSummary => skillSummary.studentRenaissanceId == studentRenaissanceId);
    let skillSummary: SkillPracticeSummary[] = studentSkillSummary.map(studentSkillSummary => {
      let skillSummary = {
        lastSkillPracticedDate: studentSkillSummary.lastSkillPracticedDate,
        subject: studentSkillSummary.subject,
        studentRenaissanceId: studentSkillSummary.studentRenaissanceId
      };
      return skillSummary;
    });

    return skillSummary;
  }

  private getStudentSkillsFromSkillDetail(studentRenaissanceId: string): Skill[] {
    let studentSkillDetail = this.skillDetail.filter(skillDetail => skillDetail.studentRenaissanceId == studentRenaissanceId);
    let skills = studentSkillDetail.map(skillDetail => {
      let skill: Skill = {
        correctAnswers: skillDetail.correctAnswers,
        durationInMinutes: skillDetail.durationInMinutes,
        totalAnswers: skillDetail.totalAnswers,
        lastPracticedDate: skillDetail.lastPracticedDate,
        skillShortName: skillDetail.skillShortName,
        product: skillDetail.product,
        subject: skillDetail.subject,
        productId: skillDetail.productId,
        standardId: skillDetail.standardId,
        standardSetId: skillDetail.standardSetId,
        domainId: skillDetail.domainId,
        renaissanceSkillId: skillDetail.renaissanceSkillId,
        contentActivityId: skillDetail.contentActivityId,
        standardSetMetadata: skillDetail.standardSetMetadata
      };
      return skill;
    });

    return skills;
  }

  private getStudentAssessmentsFromAssessmentDetail(studentRenaissanceId: string): Assessment[] {
    let studentAssessmentDetail = this.assessmentDetail.filter(assessment => assessment.studentRenaissanceId == studentRenaissanceId);
    let assessments: Assessment[] = studentAssessmentDetail.map(assessmentDetail => {
      let assessment: Assessment = {
        completedDate: assessmentDetail.completedDate,
        districtIsProficient: assessmentDetail.districtIsProficient,
        renaissanceIsProficient: assessmentDetail.renaissanceIsProficient,
        assessmentTimeInSeconds: assessmentDetail.assessmentTimeInSeconds,
        subject: assessmentDetail.subject,
        productId: assessmentDetail.productId,
        assessmentType: assessmentDetail.assessmentType,
        percentileRank: assessmentDetail.percentileRank,
        scaledScore: assessmentDetail.scaledScore,
        currentSGP: assessmentDetail.currentSGP,
        cbmAssessmentId: assessmentDetail.cbmAssessmentId,
        cbmGradeId: assessmentDetail.cbmGradeId,
        accuracy: assessmentDetail.accuracy,
        correctPerMinute: assessmentDetail.correctPerMinute,
        cbmBenchmarkCategory: assessmentDetail.cbmBenchmarkCategory,
        lexile: assessmentDetail.lexile
      };
      return assessment;
    });

    return assessments;
  }

  private getStudentReadingPracticeSummaryFromReadingPracticeSummaryDetail(studentRenaissanceId: string): ReadingPracticeSummary {
    let studentReadingPracticeSummary = this.readingPracticeSummaryDetail.find(readingPracticeSummaryDetail => readingPracticeSummaryDetail.studentRenaissanceId == studentRenaissanceId)
      || {} as ReadingPracticeSummary;
    return studentReadingPracticeSummary;
  }

  private getStudentReadingPracticesFromReadingPracticeDetail(studentRenaissanceId: string): ReadingPractice[] {
    let studentReadingPracticeDetail = this.readingPracticeDetail.filter(readingPractice => readingPractice.studentRenaissanceId == studentRenaissanceId);
    let readingPractices: ReadingPractice[] = studentReadingPracticeDetail.map(readingPracticeDetail => {
      let readingPractice = {
        contentTitle: readingPracticeDetail.contentTitle,
        author: readingPracticeDetail.author,
        atosLevel: readingPracticeDetail.atosLevel,
        lexileLevel: readingPracticeDetail.lexileLevel,
        lexileLabel: readingPracticeDetail.lexileLabel,
        contentLanguage: readingPracticeDetail.contentLanguage,
        percentCorrect: readingPracticeDetail.percentCorrect,
        arQuizNumber: readingPracticeDetail.arQuizNumber,
        product: readingPracticeDetail.product,
        mostRecentPracticedDate: readingPracticeDetail.mostRecentPracticedDate,
        finishedBook: readingPracticeDetail.finishedBook,
        totalSecondsRead: readingPracticeDetail.totalSecondsRead
      };
      return readingPractice;
    });

    let aggregatedReadingPractices = this.aggregateReadingPractices(readingPractices);
    return aggregatedReadingPractices;
  }

  private aggregateReadingPractices(readingPractices: ReadingPractice[]): ReadingPractice[] {

    //group practices with the same quiz number and content language
    let readingPracticeGroupings = new Map<any, ReadingPractice[]>();
    let readingPracticesWithQuizNumber = readingPractices.filter(readingPractice => readingPractice.arQuizNumber != null);

    readingPracticesWithQuizNumber.forEach(readingPractice => {
      if (readingPracticeGroupings.has(readingPractice.arQuizNumber + readingPractice.contentLanguage)) {
        readingPracticeGroupings.get(readingPractice.arQuizNumber + readingPractice.contentLanguage)?.push(readingPractice);
      }
      else {
        readingPracticeGroupings.set(readingPractice.arQuizNumber + readingPractice.contentLanguage, [readingPractice])
      }
    });

    //aggregate practices that have the same quiz number
    let aggregatedReadingPractices: ReadingPractice[] = [];
    readingPracticeGroupings.forEach(readingPracticeGrouping => {
      aggregatedReadingPractices.push(this.aggregateReadingPractice(readingPracticeGrouping));
    });

    //Add practices with no quiz number to final list since they do not need to be aggregated
    let readingPracticesWithNoQuizNumber: ReadingPractice[] = readingPractices.filter(readingPractice => readingPractice.arQuizNumber == null);
    let allReadingPractices = [...aggregatedReadingPractices, ...readingPracticesWithNoQuizNumber];

    let sortedReadingPractices = allReadingPractices.sort((a, b) => DateHelper.compareByDate(a.mostRecentPracticedDate, b.mostRecentPracticedDate));
    return sortedReadingPractices;
  }

  private aggregateReadingPractice(readingPractices: ReadingPractice[]): ReadingPractice {

    let arReadingPractice = readingPractices.find(practice => practice.product == ReadingPracticeProducts.AR);
    let myonReadingPractice = readingPractices.find(practice => practice.product == ReadingPracticeProducts.MYON);

    let dates = readingPractices.map(readingPractice => readingPractice.mostRecentPracticedDate);
    let mostRecentPracticedDate = dates.sort((a, b) => DateHelper.compareByDate(a, b))[0];

    let aggregatedReadingPractice: ReadingPractice = {
      mostRecentPracticedDate: mostRecentPracticedDate,
      contentTitle: arReadingPractice?.contentTitle ?? myonReadingPractice?.contentTitle!,
      author: arReadingPractice?.author ?? myonReadingPractice?.author!,
      contentLanguage: arReadingPractice?.contentLanguage ?? myonReadingPractice?.contentLanguage!,
      atosLevel: arReadingPractice?.atosLevel ?? myonReadingPractice?.atosLevel!,
      lexileLevel: arReadingPractice?.lexileLevel ?? myonReadingPractice?.lexileLevel!,
      lexileLabel: arReadingPractice?.lexileLabel ?? myonReadingPractice?.lexileLabel!,
      product: arReadingPractice?.product ?? myonReadingPractice?.product!,
      arQuizNumber: arReadingPractice?.arQuizNumber ?? myonReadingPractice?.arQuizNumber!,
      percentCorrect: arReadingPractice?.percentCorrect!,
      totalSecondsRead: myonReadingPractice?.totalSecondsRead!,
      finishedBook: myonReadingPractice?.finishedBook!,
    }
    return aggregatedReadingPractice;
  }

  public async getStandardSetsForClass(): Promise<StandardSet[]> {
    if (this.classStandardSets && this.classStandardSets.length > 0) {
      return this.classStandardSets;
    }

    // No class selected -> get the first class and update the class activity.
    // This is primarily set so the search page is guaranteed to have a class.
    else if (!this.selectedClass$.value) {
      let classes = await this.getClasses();
      let selectedClass = classes[0];

      await this.updateClassActivity(selectedClass.className, selectedClass.classRenaissanceId, selectedClass.classAppTags, selectedClass.userRenaissanceId);
    }

    return await this.graphqlService.getTeacherStandardSets(this.selectedClass$.value!.teacherRenaissanceId);
  }
}
