<div *ngIf="!!nearpodLink" id="pendo-nearpod-skill" class="action-link-section">
  <div class="link-description">{{nearpodLink.description}}</div>
  <a id="nav-help" (click)="navigateToProduct($event, nearpodLink.link,'nearpodLink',false)"  [href]="nearpodLink.link" attr.aria-label="{{nearpodLink.name}} (opens in a new tab)"
    class="action-link" target="_blank" rel="noopener noreferrer">
    <img [src]="nearpodLink.imageUrl" alt="" class="action-product-logo" />
    <span class="link-name">{{nearpodLink.name}}</span>
    <span class="glyph glyph-externallink"></span>
  </a>
</div>
<div *ngIf="!!freckleLink" id="pendo-freckle-skill" class="action-link-section">
  <div class="link-description">{{freckleLink.description}}</div>
  <ng-container *ngIf="!adminHandlerService.isAdmin(); else adminTemplate">
    <ng-container *ngIf="!featureToggleService.isTrue('use-new-launch'); else newlaunch">
      <a id="nav-help" (click)="navigateToProduct($event, freckleLink.link,'freckleLink',false)"  [href]="freckleLink.link" attr.aria-label="{{freckleLink.name}} (opens in a new tab)"
        class="action-link" [id]="pendoId" data-test="freckle-action-link" target="_blank" rel="noopener noreferrer">
        <img [src]="freckleLink.imageUrl" alt="" class="action-product-logo" />
        <span class="link-name">{{freckleLink.name}}</span>
        <span class="glyph glyph-externallink"></span>
      </a>
    </ng-container>
    <ng-template #newlaunch>
      <a id="nav-help" href="#" (click)="navigateToProduct($event, freckleLink.link,'freckleLink',true)"  (keyup.enter)="navigateFreckleByLaunchControl($event, freckleLink.link)"
        attr.aria-label="{{freckleLink.name}} (opens in a new tab)" class="action-link" [id]="pendoId"
        data-test="freckle-action-link">
        <img [src]="freckleLink.imageUrl" alt="" class="action-product-logo" />
        <span class="link-name">{{freckleLink.name}}</span>
        <span class="glyph glyph-externallink"></span>
      </a>
    </ng-template>
  </ng-container>
  <ng-template #adminTemplate>
    <div id="nav-help" class="action-link-disabled" data-test="freckle-action-link-disabled">
      <div class="action-link-container">
        <img [src]="freckleLink.imageUrl" alt="" class="action-product-logo" />
        <div class="action-link-text">
          <span class="link-name">{{freckleLink.name}}</span>
          <available-to-teachers id="pendo-available-to-teachers-text-take-action" class="take-action">
          </available-to-teachers>
        </div>
        <span class="glyph glyph-externallink"></span>
      </div>
    </div>
  </ng-template>
</div>
<div *ngIf="!!laliloLink" id="pendo-lalilo-skill" class="action-link-section">
  <div class="link-description">{{laliloLink.description}}</div>
  <ng-container *ngIf="!featureToggleService.isTrue('use-new-launch'); else newlaunch">
    <a id="nav-help" (click)="navigateToProduct($event, laliloLink.link,'laliloLink',false)" [href]="laliloLink.link" attr.aria-label="{{laliloLink.name}} (opens in a new tab)"
      class="action-link" target="_blank" rel="noopener noreferrer">
      <img [src]="laliloLink.imageUrl" alt="" class="action-product-logo" />
      <span class="link-name">{{laliloLink.name}}</span>
      <span class="glyph glyph-externallink"></span>
    </a>
  </ng-container>
  <ng-template #newlaunch>
    <a id="nav-help" href="#" (click)="navigateToProduct($event, laliloLink.link,'laliloLink',true)" (keyup.enter)="navigateLaliloByLaunchControl($event, laliloLink.link)"
      attr.aria-label="{{laliloLink.name}} (opens in a new tab)" class="action-link">
      <img [src]="laliloLink.imageUrl" alt="" class="action-product-logo" />
      <span class="link-name">{{laliloLink.name}}</span>
      <span class="glyph glyph-externallink"></span>
    </a>
  </ng-template>
</div>
<div *ngIf="!!savvasLink" id="pendo-savvas-skill" class="action-link-section">
  <div class="link-description">{{savvasLink.description}}</div>
  <a id="nav-help" (click)="navigateToProduct($event, savvasLink.link, 'savvasLink', false)" [href]="savvasLink.link" attr.aria-label="{{savvasLink.name}} (opens in a new tab)"
    class="action-link other-action-link" target="_blank" rel="noopener noreferrer">
    <img [src]="savvasLink.imageUrl" alt="" class="other-action-logo" />
    <span class="link-name">{{savvasLink.name}}</span>
    <span class="glyph glyph-externallink"></span>
  </a>
</div>
