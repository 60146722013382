import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppRoutingGuard } from './app-routing.guard';
import { ActivitySummaryComponent } from './home/activity-summary/activity-summary.component';
import { SkillsPracticeComponent } from './home/skills-practice/skills-practice.component';
import { SearchResultsComponent } from './home/search/search-results/search-results.component';
import { LogOutComponent } from './home/logout/logout.component';
import { DegradedComponent } from './home/degraded/degraded.component';
import { SuperstarsComponent } from './home/superstars/superstars.component';
import { SummerMathComponent } from './home/summer-math/summer-math.component';
import { SynchingComponent } from './home/synching/synching.component';
import { SetUpSavvasComponent } from './home/set-up-savvas/set-up-savvas.component';

const routes: Routes = [
  { path: 'search', component: SearchResultsComponent, canActivate: [AppRoutingGuard], title: "Renaissance Teacher Search" },
  { path: 'summary', component: ActivitySummaryComponent, canActivate: [AppRoutingGuard], title: "Renaissance Teacher Insights" },
  { path: 'skills', component: SkillsPracticeComponent, canActivate: [AppRoutingGuard] },
  { path: 'superstars', component: SuperstarsComponent, canActivate: [AppRoutingGuard] },
  { path: 'math', component: SummerMathComponent, canActivate: [AppRoutingGuard] },
  { path: 'setupsavvas', component: SetUpSavvasComponent, canActivate: [AppRoutingGuard], title: "Savvas Curriculum Integration" },
  { path: 'logout', component: LogOutComponent, canActivate: [AppRoutingGuard]},
  { path: 'synching', component: SynchingComponent, canActivate: [AppRoutingGuard]},
  { path: 'synching/launch/task/:task/applicationId/:applicationId', component: SynchingComponent, canActivate: [AppRoutingGuard]},
  { path: 'error', component: DegradedComponent, canActivate: [AppRoutingGuard]},
  { path: 'error/launch/task/:task/applicationId/:applicationId', component: DegradedComponent, canActivate: [AppRoutingGuard]},
  { path: '**', redirectTo: 'summary' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
