<div class="left-nav-container renaissance-style-scoped">
  <div class="left-nav-content">
    <left-nav-intro-animation></left-nav-intro-animation>
    <ng-container *ngIf="isIntroAnimationComplete">
      <button [id]="sendFeedbackId" class="send-feedback-btn ren-btn" (click)="pendoTrackFeedback()">
        <span class="glyph-bubble6"></span> Send Feedback
      </button>
      <div class="left-toggle" >
        <img class="ren-next_logo" alt="Renaissance Next logo" src="../../../assets/logos/RenaissanceNext_outlinedBLK.svg">
        <div *ngIf="!hideOptOut && !isAdmin" class="revert-to-legacy-mode-sec">
           <a class="revert-to-legacy-mode" href="#" (click)="showOptOutModal($event)"
          aria-label="Revert to legacy mode"><span class="glyph-undo2"></span><span class="revert-to-legacy-mode-text">Revert to legacy mode</span></a>
        </div>
        <div class="toggle-buttons" *ngIf="isAdmin">
          <button
            (click)="goToLeaderRedirectUrl()"
            [class.active]="false">
            Leader
          </button>
          <button
            [class.active]="true">
            Teacher
          </button>
        </div>
      </div>

      <ng-container *ngIf="appNavDegraded">
        <div class="app-nav-degraded-warning">
          <div class="app-nav-degraded-warning-glyph-container">
            <span class="glyph-warning2"></span>
          </div>
          <div class="app-nav-degraded-warning-message">
            Something went wrong, so you might see extra products. Try to do your work while we fix the issue.
          </div>
        </div>
      </ng-container>

      <ng-container>

        <div class="apps-section">
          <div class="apps-section-header-container">
            <h2 class="apps-section-header">My Data
            </h2>
          </div>
          <div *ngFor="let app of availableData">
            <!-- Add aria attribute if button open sidenav -->
            <div [attr.id]="app.id" class="link-logo-container" tabindex="0" (click)="navigate(app)" (keydown.enter)="navigate(app)"
              role="button" [attr.aria-expanded]="hasSidenav(app) ? isSidenavExpanded(app) : null">
              <img class="link-logo non-hover" alt="" src="../../../assets/logos/{{app.imagePrefix}}_bw.svg">
              <img class="link-logo outline" alt="" src="../../../assets/logos/{{app.imagePrefix}}_outline.svg">
              <div class="link-text">{{app.displayName}}</div>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="apps-section">
        <div class="apps-section-header-container">
          <p class="apps-section-header">My Apps
          </p>
          <a class="learn-more-link" href="https://www.renaissance.com/products/" target="_blank"
            aria-label="Learn more link opens in a new tab">Learn More<span class="glyph glyph-externallink"></span>
          </a>
        </div>
        <div *ngIf="!featureToggleService.isTrue('use-new-launch'); else newlaunch">
          <!-- Add aria attribute if button open sidenav -->
          <ng-container *ngTemplateOutlet="appTemplate; context: { apps: availableApps, type: 'v1'}"></ng-container>
        </div>
        <ng-template #newlaunch>
          <!-- Add aria attribute if button open sidenav -->
          <ng-container *ngTemplateOutlet="appTemplate; context: { apps: availableV2Apps, type: 'v2'}"></ng-container>
         </ng-template>
      </div>
      <div class="apps-section other-apps-spacing" *ngIf="otherAvailableApps && otherAvailableApps.length > 0">
        <div class="apps-section-header-container">
          <h2 class="apps-section-header">Other Apps</h2>
        </div>
        <ng-container *ngTemplateOutlet="appTemplate; context: { apps: otherAvailableApps, type: 'other'}"></ng-container>
      </div>
    </ng-container>
  </div>
</div>

<div class="sidenavs">
  <ng-container *ngFor="let sideNav of sideNavs">
    <div *ngIf="sideNav.appNavItem.children">
      <mat-sidenav-container hasBackdrop="false">
        <mat-sidenav #sidenav class="sidenav" [(opened)]="sideNav.stateIsOpen" mode="over" fixedInViewport="true"
          position="start">
          <app-link-selector [selectedApp]="sideNav.appNavItem" [appCategories]="sideNav.appNavItem.children"
            [expandDisabled]="!!sideNav.disableLinkSelectorAccordions"
            (closeSidenavEvent)="closeSidenav()"></app-link-selector>
        </mat-sidenav>
      </mat-sidenav-container>
      <!-- darkened overlay for when sidenav is open. -->
      <div class="sidenav-cover" [class.active]="sideNav.stateIsOpen" (click)="closeSidenav()"></div>
    </div>
  </ng-container>
</div>

<ng-template #appTemplate let-apps="apps" let-type="type">
    <div *ngFor="let app of apps">
      <div [class.fade-in-ani]="playFadeInAnimation"
        [ngStyle]="{'--offset': (playFadeInAnimation ? getAppNavTranslateOffset(app) : '0px')}"
        [attr.id]="app.id"
        class="link-logo-container"
        tabindex="0" (click)="directAppLinkClick(app, type)"
        (keydown.enter)="directAppLinkClick(app, type)"
        [attr.role]="!!app.children ? 'button' : 'link'"
        [attr.aria-label]="app.displayName"
        [attr.aria-expanded]="hasSidenav(app) ? isSidenavExpanded(app) : null">
        <img class="link-logo non-hover" [ngClass]="{'renu-logo':isRenU(app)}" alt=""
          src="../../../assets/logos/{{app.imagePrefix}}_bw.svg">
        <img class="link-logo outline" [ngClass]="{'renu-logo':isRenU(app)}" alt=""
          src="../../../assets/logos/{{app.imagePrefix}}_outline.svg">
        <div *ngIf="!isRenU(app)" class="link-text">{{app.displayName}}</div>
      </div>
    </div>
</ng-template>
