import { Injectable } from '@angular/core';
import { SearchResult } from 'src/app/home/search/search-results/search-result.model';
import { PendoService } from '../pendo/pendo.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class MyOnLinkGeneratorV2Service {

  constructor(private pendoService: PendoService, private userService: UserService) { }

  public getReportsPageDeepLink(): string {
    return 'MYON_REPORTS';
  }

  public getMyOnPageDeepLink(searchResult?: SearchResult, queryParams: string = ''): string {
    this.pendoService.sendEvent("MyOnLinkClickedInSearch", { searchResultClickedOn: searchResult});
    const userId = [this.userService.getUserId()];

    let projectId = '';
    if (searchResult && !queryParams) {
      projectId = this.getProjectIdFromSearchResult(searchResult.product_skill_id) ?? '';
    } else if (queryParams && queryParams.length > 0) {
      projectId = this.getProjectIdFromQuery(queryParams) ?? '';
    }

    return `projects-duplicate?project_id=${projectId}&user_ids=${userId}`;
  }

  public getDeepLink(studentRenaissanceId: string | undefined): string {
    return `admin-students-view-user-id=${studentRenaissanceId}`;
  }

  public getProjectIdFromQuery(queryString: string): string | null {
    const params = new URLSearchParams(queryString);
    return params.get('project_id');
  }

  public getProjectIdFromSearchResult(input: string): string | null {
    const match = input.match(/PROJECTID-(\d+)__/);
    return match ? match[1] : null;
  }
}
