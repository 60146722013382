
export enum RecommendationType {
  None,
  Star,
  StarLexile,
  ArLexile,
  MyonLexile,
  FreckleReadingSkill,
  LaliloReadingSkill,
  NeedsHelp,
  Appropriate,
  NeedsChallenge,
  EndOfProgression
}
