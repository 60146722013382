import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LexileHelperService {

  constructor() { }

  public convertLexileToNumber(lexileValueString: string | null): number | null {
    if (!lexileValueString) {
      return null;
    }

    if (lexileValueString == 'BR') {
      return 0;
    }

    const isBeginningReader = lexileValueString?.toLowerCase().includes('br');

    const numericValue = lexileValueString?.replace(/[^-\d]/g, '');
    
    return isBeginningReader ? -1 * parseInt(numericValue) : parseInt(numericValue);
  }
}
