<hr *ngIf="!featureToggleService.isTrue('use-whole-class-view')" aria-hidden="true" />
<div class="recommendation-container">

  <div *ngIf="showMultipleProductTypesRecommendation">
    <p><strong>Recommendation:</strong> {{  getMultipleProductsRecommendationHeaderText() }}</p>
    <div class="multiple-products-multiple-types-container">
      <div class="product-category">
        <p>Skills Practice</p>
        <ng-container *ngFor="let recommendation of productRecommendations;">
          <div *ngIf="recommendation.recommendationProduct == 'SkillsPractice'" class="product-recommendation multiple-products-multiple-types-recommendation">
            <img [src]="recommendation.recommendationLogo" alt="" class="logo" />
            <div class="multiple-products-multiple-types-recommendation-text recommendation-text" [innerHTML]="recommendation.recommendationText"></div>
          </div>
        </ng-container>
      </div>
      <div class="product-category">
        <p>Reading Practice</p>
        <ng-container *ngFor="let recommendation of productRecommendations;">
          <div *ngIf="recommendation.recommendationProduct == 'ReadingPractice'" class="product-recommendation multiple-products-multiple-types-recommendation">
            <img [src]="recommendation.recommendationLogo" alt="" class="logo" />
            <div class="multiple-products-multiple-types-recommendation-text recommendation-text" [innerHTML]="recommendation.recommendationText"></div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div *ngIf="showSingleProductTypeMultipleProductsRecommendation">
    <p><strong>Recommendation:</strong> {{  getMultipleProductsRecommendationHeaderText() }}</p>
    <div class="multiple-products-single-type-container">
      <ng-container *ngFor="let recommendation of productRecommendations;">
        <div class="product-recommendation multiple-products-single-type-recommendation">
          <img [src]="recommendation.recommendationLogo" alt="" class="logo" />
          <div class="multiple-products-single-type-recommendation-text recommendation-text" [innerHTML]="recommendation.recommendationText"></div>
        </div>
      </ng-container>
    </div>
  </div>

  <div *ngIf="showSingleProductRecommendation">
    <div class="product-recommendation">
      <img [src]="productRecommendations[0].recommendationLogo" alt="" class="logo" />
      <div class="recommendation-text" [innerHTML]="productRecommendations[0].recommendationText"></div>
    </div>
  </div>

  <div *ngIf="showNoProductRecommendation">
    <p>Keep going with your classwork and instruction. You've got this!</p>
    <div class="product-recommendation">
      <img [src]="productRecommendations[0].recommendationLogo" alt="" class="logo" />
      <div class="no-product-recommendation-text recommendation-text" [innerHTML]="productRecommendations[0].recommendationText"></div>
    </div>
  </div>
</div>

