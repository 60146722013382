import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { RgpStandardSet } from 'src/app/shared/models/rgp-standard-set.model';

export interface RenaissanceJWT {
  jti: string;
  exp: number;
  aud: string;
  rid: string;
  clientid: string;
  email: string;
  homeuri: string;
  logouturi: string;
  keepaliveuri: string;
  timeoutminutes: number;
  keepaliveintervalminutes: number;
  loginurl: string;
  globalbase: string;
  culture: string;
  featureset: string;
  rgpBaseUrl: string;
  skipnavaccesscheck?: boolean;
  hideoptout: boolean;
  betaisover: boolean;
  roleid: string;
  nextrole: string;
  standardsets: RgpStandardSet[];
  deeplink: string;
}

@Injectable({
  providedIn: 'root'
})
export class JwtParserService {

  constructor() { }

  decode(token: string): RenaissanceJWT {
    const decoded = jwtDecode<RenaissanceJWT>(token);
    return decoded;
  }

  verify(token: string): boolean {
    const decoded = jwtDecode<RenaissanceJWT>(token);
    if (1000 * decoded?.exp >= Date.now()) {
      return true;
    }
    return false;
  }
}
