import { EnvironmentShape } from "./environment.schema";

export const environment: EnvironmentShape = {
  production: false,
  launchdarklyClientId: '63ffdda3f928441354728819',
  ldRelayProxyUrl: 'https://teacher-launch-darkly.dev.renaissance.com',
  graphqlUrl: 'https://teach.dev.renaissance.com/v1/graphql',
  cloudGraphqlUrl: 'https://teacher-api.dev.renaissance.com/v1/graphql',
  expiredJwtRedirectUrl: 'https://schools.renaissance.com/',
  systemStatusRedirectUrl: "https://status.renaissance.com/",
  starSkillsUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/Skills/',
  appNavUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/AppNav',
  searchLambdaUrl: 'https://teacher-search-api.dev.renaissance.com/search',
  headerConfig: {
    url: 'https://resources-zone99.renaissance-go.com/scripts/renlearn/customelements/renaissanceheader',
    version: 'RenaissanceHeader-CE/RenaissanceHeader-CE-15.0.112.zip'
  },
  rgpResourceSiteUrl: 'https://resources-devel.renaissance-golabs.com',
  helpRedirectBaseUrl: 'https://help2redirect.renaissance.com/redir.asp?c=',
  chatConfigScriptUrl: 'https://chatfiledev.renlearn.com/whoson_realtime.js',
  sessionKeepAliveUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/Session/KeepAlive',
  apiCacheTtl: 42300,
  superstarUrl: 'https://superstars-api.dev.renaissance.com',
  launchControlServiceUrl: 'https://global-dv.renaissance-golabs.com/launchcontrol/launch/',
  appNavV2Url: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/AppNavV2',
  rgpTeacherPreferencesUrl: 'https://global-dv.renaissance-golabs.com/teacherjourneyadapter/NextPreference/TeacherPreferences',
  ltiServiceIntegrationUrl: 'https://teacher-lti.dev.renaissance.com/api/lti/savvas',
};
