import { Injectable } from '@angular/core';
import { ProductIds } from 'src/app/services/product-info/product-info.service';

@Injectable({
  providedIn: 'root'
})
export class StarHelperService {

  constructor() { }

  public IsEnglishStarReadingProductId(productId: string) {
    return productId === ProductIds.StarReading ||
      productId === ProductIds.StarEarlyLiteracy
  }

}
