import { Injectable } from '@angular/core';
import { ReadingPractice } from 'src/app/shared/models/reading-practice.model';
import { Recommendation, RecommendationType } from 'src/app/shared/models/recommendation.model';
import { StudentContext } from 'src/app/shared/models/student.model';
import { DateHelper } from 'src/app/util/date-helper/date-helper';

@Injectable({
  providedIn: 'root'
})
export class MyonRecommendationService {

  constructor() { }

  public async getMyonLexileRecommendation(selectedStudent: StudentContext, allStudents: StudentContext[]): Promise<Recommendation | null> {
    if (!selectedStudent.readingPractices || selectedStudent.readingPractices.length === 0) {
      return null;
    }

    const recentMyonReadingPractices = selectedStudent.readingPractices.filter(practice => 
      practice.product.toLowerCase() === 'myon' &&
      DateHelper.isWithinTwoWeeksOfToday(practice.mostRecentPracticedDate) &&
      !!practice.lexileLevel
    );

    if (!recentMyonReadingPractices || recentMyonReadingPractices.length === 0) {
      return null;
    }

    const averageLexileLevel = this.getAverageLexileLevel(recentMyonReadingPractices);
    const lowerLexileValue = averageLexileLevel - 150;
    const upperLexileValue = averageLexileLevel + 150;

    const myonLexileRecommendation: Recommendation = {
          rationale: 'rationale',
          nudgeText: { skillsPractice: 'myonSkillsPractice', readingPractice: 'myonReadingPractice' },
          skillMetadata: null,
          recommendationType: RecommendationType.MyonLexile,
          lowerLexile: lowerLexileValue,
          upperLexile: upperLexileValue,
          productActionLinks: {},
          productId: 'MYON'
        } as Recommendation;
        
        return myonLexileRecommendation;
  }

    private getAverageLexileLevel(readingPractices: ReadingPractice[]) { 
      return readingPractices.reduce((total, practice) => total + practice.lexileLevel, 0) / readingPractices.length
    }
}
